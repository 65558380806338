::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.MuiBackdrop-root,
.MuiDrawer-root {
  position: unset !important;
  box-shadow: none;
}

.MuiDrawer-paper {
  box-shadow: none !important;
}
